<template>
    <div class="vcard">
        <h1 class="v-card__title">Vcards</h1>
        <technicalSheets/>
        <catalogues/>
        <branches/>
        <div class="vcard__cards">
            <createVcardButton/>
            <tableVcard/>
        </div>
    </div>
</template>

<script>
const technicalSheets = () => import(/* webpackChunkName: "vCard" */'./components/technical.vue')
const createVcardButton = () => import('./components/createCardButton.vue')
const tableVcard = () => import('./components/cardsTable.vue')
const catalogues = () => import('./components/catalogues.vue')
const branches = () => import('./components/branches.vue')

export default {
    name:"vCard",
    components:{
        technicalSheets,
        createVcardButton,
        tableVcard,
        catalogues,
        branches
    },

}
</script>
