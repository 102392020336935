<template>
    <div class="technical">
        <button 
            class="technical__create"
            @click="showModal"
        >
            Añadir Ficha Técnica
        </button>
        <div
            v-if="getTechnicalSheets.length > 0" 
            class="technical__list">
            <div 
                v-for="i in getTechnicalSheets"
                :key="i.id"    
                class="technical__list__component">
                <img 
                    :src="i.image" 
                    alt="Technical sheet" 
                    class="technical__list__component__image"
                >
                <button 
                    class="technical__list__component__delete"
                    @click="deleteTechnical(i.id, i.image)"    
                >
                    Eliminar</button>
            </div>
        </div>
        <div 
            class="technical__default"
            v-else>
            <p>Sin fichas técnicas </p>
        </div>
        <TechincalSheetModal 
            v-show="isShownModal"
            @closeModal="showModal"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const TechincalSheetModal = () =>
  import(
    /* webpackChunkName: "TechnicalSheet" */ "../components/createTechnicalModal.vue"
  );

export default {
    data() {
        return {
            image:null,
            isShownModal: false
        }
    },

    methods: {
        async onFileChange(event) {
            this.image = event.target.files[0]
            try {
                await this.createTechnicalSheet(this.image)
                this.$toast.success("Se agrego la Ficha técnica");
            } catch (error) {
                this.$toast.error(
                  "Ocurrio un error al intentar agregar la Ficha técnica"
                );
            }
            
        },

        showModal() {
            this.isShownModal = !this.isShownModal
        },

        async deleteTechnical(id, url){
            try {
                await this.deleteTechnicalSheet({
                    sheetId: id,
                    imageUrl: url
                });
                this.$toast.success("Se elimino correctamente la Ficha técnica");
            } catch (error) {
                this.$toast.error(
                  "Ocurrio un error al intentar eliminar la Ficha técnica"
                ); 
            }
        },

        selectImage() {
            this.$refs.file.click()
        },

        ...mapActions("vCard", ["createTechnicalSheet", "deleteTechnicalSheet"])
    },

    computed: {
        ...mapGetters("vCard", ["getTechnicalSheets"])
    },

    components: {
        TechincalSheetModal
    }

    
}
</script>

<style>

</style>
